#react-root body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#react-root code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#react-root .alignCenter {
  text-align: center;
}

#react-root .box {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*
#react-root .container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#react-root .container .alignCenter {
  align-self: center;
}*/

#react-root .rcard {
  border: 0;
  /* box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%); 
 border: 1px solid #EFF2F5;
 border-radius: 0.625rem;
*/
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;

  border: 1px solid #e5e5e5;
  border-radius: 6px;
  box-shadow: 0 2px 22px 0 rgb(0 0 0 / 10%);
}


#react-root body {
  /*    background-color: #F5F8FA; */
  display: flex;
  flex-direction: column;
  color: #181C32;
  background-color: #FFFFFF;
}

#react-root .drawSignaturePadContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}

#react-root .drawSignaturePadContainer .drawSignaturePadWrappper {
  align-self: center;
  margin-top: 30px;

  /*border: 1px solid rgb(247, 241, 241);
    border-radius: 6px;
    padding: 15px 15px 15px 15px;
    background-color: rgb(255, 255, 255);
    */
}

#react-root .actionsContainer {
  border: 1px solid rgb(247, 241, 241);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  margin-top: 20px;
}

#react-root .actionsWrapper {
  padding: 20px 20px 20px 20px;
}

#react-root .otherActionsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  align-items: center;
}

#react-root .drawSignaturePadButtonsWrapper {
  display: flex;
  justify-content: center;
  /*
  flex-direction : row;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;*/

}

.signaturePadSection {
  display: flex;
  justify-content: center;
}

.typeSignaturePadContainer {
  width: 725px;
  align-self: center;
  margin-bottom: 20px
}

.sliderCss {
  width: 100%;
  max-width: 100%;
}



.typeSignaturePadWrapper {

  padding: 20px 20px 20px 20px;

}

.typeSignatureActionsContainer {
  border: 1px solid rgb(247, 241, 241);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  padding: 32px 20px 32px 20px;
}

.typeSignatureActionsSection1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.typeSignatureActionsSection1Child1 {
  display: flex;
  width: 50%;
}

.typeSignatureActionsSection1Child2 {}

.typeSignatureActionsSection2 {
  display: flex;
  justify-content: center;
}

.rgridButton {
  border-radius: 20px 0px 0px 20px !important;
  line-height: 0.95 !important;
  color: #9f9999 !important;
  background: white !important;
  border: 1px solid #dcd4d4 !important;
  outline: none;
}

.rlistButton {
  border-radius: 0px 20px 20px 0px !important;
  line-height: 0.95 !important;
  color: #9f9999 !important;
  background: white !important;
  border: 1px solid #dcd4d4 !important;
  outline: none;
}

#react-root .active {
  color: black !important;
}


@media only screen and (max-width: 480px) {
  .typeSignaturePadContainer {
    width: 380px;
    align-self: center;
    margin-bottom: 20px
  }

  .sliderCss {
    width: 100%;
  }

  .typeSignatureActionsSection1 {
    flex-direction: column-reverse;
  }

  .typeSignatureActionsSection1Child1 {
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: center;
  }

  .typeSignatureActionsSection1Child2 {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

/*********************************  INPUT FIELD CSS *********************************/

.typeSignatureField,
input[type="text"] {
  display: block;
  max-width: 723px;
  width: 100%;
  height: 56px;
  margin: 0 auto 6px;
  padding: 0 22px;
  background-clip: padding-box;
  border-radius: 50px;
  border: 1px solid #e5e5e5;
  /*  box-shadow: 0 2px 22px 0 rgb(0 0 0 / 10%); */
  font-size: 18px;
  color: #767676;
  transition: .2s border-color;
}

.typeSignatureField:focus,
input[type="text"]:focus {
  border: 1px solid #1670BE;
  outline: none;
}

input[type="text"]:focus {
  border: 1px solid #1670BE;
  outline: none;
}

#react-root input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.typeSignatureContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: auto;
  max-width: 640px;
}

.typeSignatureFieldWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}


.typeSignatureContainer2 {
  align-items: center;
  justify-content: center;
  margin-inline: auto;
  max-width: 1100px;
}

.typeSignatureBox {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
}

.typeSignatureBox .nameBox {
  font-size: 60px;
  ;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex: 1 1 0%;
}

.typeSignatureCardBox {
  padding: 3rem 2rem;
}

#react-root .row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

#react-root .row>[class*='col-'] {
  display: flex;
  flex-direction: column;
}



/***************** AUTO GRAPH CSS START *************************/
.autoGraphContainer {
  border: 1px solid rgb(247, 241, 241);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  /*padding: 32px 20px 32px 20px;*/
}


.autoGraphCardBox {
  padding: 3rem 2rem;
}

.autoGraphBox {
  min-height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
}

.autoGraph .nameBox {
  font-size: 60px;
  ;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex: 1 1 0%;
}

.autoGraphBoxTextField {
  display: block;
  max-width: 723px;
  width: 100%;
  height: 35px !important;
  margin: 0 auto 6px;
  padding: 0 22px;
  background-clip: padding-box;
  border-radius: 10px !important;
  border: 1px solid #e5e5e5;
  font-size: 14px !important;
  color: #767676 !important;
  transition: .2s border-color !important;
  font-family: cursive !important;
  padding-left: 10px !important;
}

.autoGraphselect {
  color: #767676;
  /* Text color */
  background-color: #fff;
  /* Background color */
  border: 1px solid #e5e5e5;
  /* Border color */
  border-radius: 5px;
  /* Border radius */
  padding: 6px;
  /* Padding */
  font-size: 14px;
  /* Font size */
  width: 100%;
  /* Width */
  height: 35px;
  /* Height */
  font-family: cursive;
}

.autoGraphActionsContainer {
  flex: 1;
  /* This makes the items grow to fill the container */
  width: calc(50% - 5px);
  /* Adjusted width to account for space */
  box-sizing: border-box;
  /* This includes border in width calculation */

  border: 1px solid rgb(247, 241, 241);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  padding: 6px;
  padding-bottom: 14px;
  ;

}

.autoGraphActionLabel {
  margin: 4px 0px 10px 0px;
  display: flex;
  font-size: 14px;
  font-family: cursive;
}

.autoGraphActionsUBLabel {
  margin: 4px 0px 4px 0px;
  display: flex;
  font-size: 12px;
  font-family: cursive;
}

.autoGraphFieldLabel {
  margin: 0px 0px 10px 0px;
  display: flex;
  font-size: 12px;
  font-family: cursive;
}

.autoGraphContainer .tab-content{
  padding: 0px 10px 0px 10px;
}

/***************** AUTO GRAPH CSS END *************************/

/********************************* SELECT FIELD CSS *********************************/





/**********************************  BUTTONS   ********************************/
#react-root .rbtn {
  display: inline-block;
  font-weight: 500;
  color: #181C32;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  /*
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  line-height: 1.5;
  */
  padding: 0.50rem 1.2rem;
  font-size: 1.2rem;
  line-height: 1.5;

  border-radius: 0.475rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition-duration: 0.4s;
}

#react-root .rbtn-primary {
  color: #FFFFFF;
  border-color: #009EF7;
  background-color: #009EF7;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%), 0 1px 1px rgb(0 0 0 / 8%);
}

#react-root .rbtn-primary:hover:not([disabled]) {
  color: #FFFFFF;
  border-color: #0095E8;
  background-color: #0095E8 !important;
}

#react-root .rbtn-primary-outline {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

#react-root .rbtn-primary-outline:hover:not([disabled]) {
  color: #FFFFFF;
  border-color: #0095E8;
  background-color: #0095E8 !important;
}


#react-root .rbtn-sm {
  padding: 0.55rem 1.25rem;
  font-size: 0.925rem;
  border-radius: 0.325rem;
}

#react-root .rbtn-standard {
  width: 240px;
  margin-left: 20px;
  margin-right: 20px;
}

#react-root .rbtn-round {
  border-radius: 50px;
}

/*BUTTON GROUP*/

#react-root .rbd-example>.rbtn-group {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

#react-root .rbtn-group,
#react-root .rbtn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

#react-root group>.rbtn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#react-root .rbtn-group>.rbtn:first-child {
  margin-left: 0;
}

#react-root .rbtn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

#react-root .rbtn:focus {
  box-shadow: unset !important;
}

#react-root .rbtn-group-vertical>.rbtn,
#react-root .rbtn-group>.rbtn {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

#react-root .rbtn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

#react-root .rbtn-colorPicker {
  /*
  color: #6c757d;
  background-color: #fff;
  border-color: #d3d8dc;
  padding: 10px 26px 10px 5px;*/
  line-height: 0.5;
}

#react-root .rbtn-colorPicker:hover {
  background-color: #f7f7f7 !important;
}

#react-root .rbtn-colorPicker .rcolorDot {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  padding: 0px 12px 0px 12px;
  margin-right: 10px;
}

#react-root .rbtn-colorPicker .rcolorName {
  text-align: left;
  font-Weight: 400;
}

#react-root button:disabled,
#react-root button[disabled] {
  opacity: 0.4;
  cursor: not-allowed;
}


#react-root .colorPickerSection {
  display: flex;
  width: 50%;
}

#react-root .penWidthSection {
  width: 50%;
  max-width: 300px;
}

@media only screen and (max-width: 480px) {
  #react-root .rbtn-standard {
    width: 170px;
    margin-left: 20px;
    margin-right: 20px;
  }

  #react-root .actionsWrapper {
    padding: 20px 0px 20px 0px;
  }

  #react-root .ractionsContainer {
    width: 83%;
    margin: auto;
    margin-top: 20px;
  }

  #react-root .otherActionsWrapper {
    display: unset;
  }

  #react-root .colorPickerSection {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  #react-root .penWidthSection {
    width: 80%;
    margin: auto;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 850px) {
  #react-root .rbtn-standard {
    width: 170px;
    margin-left: 20px;
    margin-right: 20px;
  }
}


/**CIRCLE OPTION STYLING*/
#react-root .circle {
  width: 25px;
  height: 25px;
  margin-left: 10px;
}

/**************************************     GENERAL             **********************************/
#react-root .circle-picker {
  max-width: 210px !important;
}

#react-root .color-picker-icon {
  width: 25px;
  height: 25px;
  background: transparent;
  cursor: pointer;
  position: relative;
  outline: none;
  border-radius: 50%;
  /*box-shadow: rgb(26 25 25) 0px 0px 0px 13.5px inset; */
  transition: box-shadow 100ms ease 0s;
}

#react-root .flexBreak {
  flex-basis: 100%;
  height: 0;
}

#react-root .flexBreakSmallScreen {}

@media only screen and (max-width: 480px) {
  #react-root .flexBreakSmallScreen {
    flex-basis: 100%;
    height: 0;
  }
}

/**************************************    FONT FAMILIES     ***************************************/

@font-face {
  font-family: 'Bad Script';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/badScript/bad-script-v16-latin-regular.woff') format('woff');
  /*
  src: url('fonts/badScript/bad-script-v16-latin-regular.eot'); // IE9 Compat Modes
  src: url('fonts/badScript/bad-script-v16-latin-regular.eot?#iefix') format('embedded-opentype'), // IE6-IE8 
  url('fonts/badScript/bad-script-v16-latin-regular.woff2') format('woff2'), // Super Modern Browsers 
  url('fonts/badScript/bad-script-v16-latin-regular.woff') format('woff'), // Modern Browsers 
  url('fonts/badScript/bad-script-v16-latin-regular.ttf') format('truetype'), // Safari, Android, iOS 
  url('fonts/badScript/bad-script-v16-latin-regular.svg#BadScript') format('svg'); // Legacy iOS 

  */

}

/* bilbo-regular - latin */
@font-face {
  font-family: 'Bilbo';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/bilbo/bilbo-v20-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/bilbo/bilbo-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/bilbo/bilbo-v20-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/bilbo/bilbo-v20-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/bilbo/bilbo-v20-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/bilbo/bilbo-v20-latin-regular.svg#Bilbo') format('svg');
  /* Legacy iOS */
}

/* bilbo-swash-caps-regular - latin */
@font-face {
  font-family: 'Bilbo Swash Caps';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/bilboSwash/bilbo-swash-caps-v22-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/bilboSwash/bilbo-swash-caps-v22-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/bilboSwash/bilbo-swash-caps-v22-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/bilboSwash/bilbo-swash-caps-v22-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/bilboSwash/bilbo-swash-caps-v22-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/bilboSwash/bilbo-swash-caps-v22-latin-regular.svg#BilboSwashCaps') format('svg');
  /* Legacy iOS */
}

/* dancing-script-regular - latin */
@font-face {
  font-family: 'Dancing Script';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/dancingScript/dancing-script-v23-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/dancingScript/dancing-script-v23-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/dancingScript/dancing-script-v23-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/dancingScript/dancing-script-v23-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/dancingScript/dancing-script-v23-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/dancingScript/dancing-script-v23-latin-regular.svg#DancingScript') format('svg');
  /* Legacy iOS */
}

/* moon-dance-regular - latin */
@font-face {
  font-family: 'Moon Dance';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/moonDance/moon-dance-v3-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/moonDance/moon-dance-v3-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/moonDance/moon-dance-v3-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/moonDance/moon-dance-v3-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/moonDance/moon-dance-v3-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/moonDance/moon-dance-v3-latin-regular.svg#MoonDance') format('svg');
  /* Legacy iOS */
}

/* great-vibes-regular - latin */
@font-face {
  font-family: 'Great Vibes';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/greatVibes/great-vibes-v14-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/greatVibes/great-vibes-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/greatVibes/great-vibes-v14-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/greatVibes/great-vibes-v14-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/greatVibes/great-vibes-v14-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/greatVibes/great-vibes-v14-latin-regular.svg#GreatVibes') format('svg');
  /* Legacy iOS */
}

/* sacramento-regular - latin */
@font-face {
  font-family: 'Sacramento';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/sacramento/sacramento-v13-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/sacramento/sacramento-v13-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/sacramento/sacramento-v13-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/sacramento/sacramento-v13-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/sacramento/sacramento-v13-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/sacramento/sacramento-v13-latin-regular.svg#Sacramento') format('svg');
  /* Legacy iOS */
}

/* alex-brush-regular - latin */
@font-face {
  font-family: 'Alex Brush';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/alexBrush/alex-brush-v20-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/alexBrush/alex-brush-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/alexBrush/alex-brush-v20-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/alexBrush/alex-brush-v20-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/alexBrush/alex-brush-v20-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/alexBrush/alex-brush-v20-latin-regular.svg#AlexBrush') format('svg');
  /* Legacy iOS */
}

/* allura-regular - latin */
@font-face {
  font-family: 'Allura';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/allura/allura-v18-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/allura/allura-v18-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/allura/allura-v18-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/allura/allura-v18-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/allura/allura-v18-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/allura/allura-v18-latin-regular.svg#Allura') format('svg');
  /* Legacy iOS */
}



/******************************    AI FONT FAMILY START ****************************************/

/*
a Angkanya Sebelas
Elatina
Barokah Signature
Travel November
Sundiary 
Salonica 
Scriptina Pro 

------------------
Rosabelia                 - downloaded
Astagina Signature        - downloaded
Batfide Signature         - downlaoded
Rightman Signature        - downlaoded
Slow Smoke                - downlaoded
Endalmin                  - downloaded
*/

/*Senja Santuy*/
@font-face {
  font-family: 'Senja Santuy';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/senjaSantuy/SenjaSantuy.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/senjaSantuy/SenjaSantuy.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/senjaSantuy/SenjaSantuy.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/senjaSantuy/SenjaSantuy.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/senjaSantuy/SenjaSantuy.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/senjaSantuy/SenjaSantuy.svg#SenjaSantuy') format('svg');
  /* Legacy iOS */
}

/* HoarselySingleLine */
@font-face {
  font-family: 'Hoarsely Single Line';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/hoarselySingleLine/HoarselySingleLine.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/hoarselySingleLine/HoarselySingleLine.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/hoarselySingleLine/HoarselySingleLine.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/hoarselySingleLine/HoarselySingleLine.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/hoarselySingleLine/HoarselySingleLine.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/hoarselySingleLine/HoarselySingleLine.svg#HoarselySingleLine') format('svg');
  /* Legacy iOS */
}

/* Sansilk  */
@font-face {
  font-family: 'Sansilk';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/sansilk/Sansilk.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/sansilk/Sansilk.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/sansilk/Sansilk.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/sansilk/Sansilk.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/sansilk/Sansilk.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/sansilk/Sansilk.svg#Sansilk') format('svg');
  /* Legacy iOS */
}

/**Really Free**/
@font-face {
  font-family: 'Really Free';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/reallyFree/ReallyFree.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/reallyFree/ReallyFree.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/reallyFree/ReallyFree.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/reallyFree/ReallyFree.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/reallyFree/ReallyFree.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/reallyFree/ReallyFree.svg#Really_Free') format('svg');
  /* Legacy iOS */
}

/*FOR PALESTINE*/
@font-face {
  font-family: 'Free for Palestine';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/forPalestine/FreeForPalestineRegular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/forPalestine/FreeForPalestineRegular.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/forPalestine/FreeForPalestineRegular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/forPalestine/FreeForPalestineRegular.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/forPalestine/FreeForPalestineRegular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/forPalestine/FreeForPalestineRegular.svg#FreeforPalestine-Regular') format('svg');
  /* Legacy iOS */
}



/* Barokah Signature */
@font-face {
  font-family: 'Barokah Signature';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/barokahSignature/BarokahSignature.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/barokahSignature//BarokahSignature.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/barokahSignature/BarokahSignature.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/barokahSignature/BarokahSignature.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/barokahSignature/BarokahSignature.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/barokahSignature/BarokahSignature.svg#BarokahSignature-Regular') format('svg');
  /* Legacy iOS */
}

/* Travel November */
@font-face {
  font-family: 'Travel November';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/travelNovember/TravelNovember.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/travelNovember/TravelNovember.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/travelNovember/TravelNovember.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/travelNovember/TravelNovember.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/travelNovember/TravelNovember.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/travelNovember/TravelNovember.svg#TravelNovember') format('svg');
  /* Legacy iOS */
}

/* Sundiary */
@font-face {
  font-family: 'Sundiary';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/sundiary/Sundiary.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/sundiary/Sundiary.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/sundiary/Sundiary.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/sundiary/Sundiary.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/sundiary/Sundiary.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/sundiary/Sundiary.svg#SundiaryDemo') format('svg');
  /* Legacy iOS */
}

/* Salonica */
@font-face {
  font-family: 'Salonica';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/salonica/Salonica.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/salonica/Salonica.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/salonica/Salonica.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/salonica/Salonica.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/salonica/Salonica.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/salonica/Salonica.svg#Salonica') format('svg');
  /* Legacy iOS */
}

/* Scriptina Pro */
@font-face {
  font-family: 'Scriptina Pro';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/scriptinaPro/ScriptinaPro.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/scriptinaPro/ScriptinaPro.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/scriptinaPro/ScriptinaPro.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/scriptinaPro/ScriptinaPro.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/scriptinaPro/ScriptinaPro.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/scriptinaPro/ScriptinaPro.svg#ScriptinaPro') format('svg');
  /* Legacy iOS */
}

/*Rosabelia*/
@font-face {
  font-family: 'Rosabelia';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/rosabelia/rosabelia.regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/rosabelia/rosabelia.regular.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/rosabelia/rosabelia.regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/rosabelia/rosabelia.regular.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/rosabelia/rosabelia.regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/rosabelia/rosabelia.regular.svg#Rosabelia') format('svg');
  /* Legacy iOS */
}

/*Astagina Signature*/
@font-face {
  font-family: 'Astagina Signature';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/astaginaSignature/AstaginaSignature.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/astaginaSignature/AstaginaSignature.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/astaginaSignature/AstaginaSignature.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/astaginaSignature/AstaginaSignature.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/astaginaSignature/AstaginaSignature.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/astaginaSignature/AstaginaSignature.svg#AstaginaSignature') format('svg');
  /* Legacy iOS */
}

/*Batfide Signature*/
@font-face {
  font-family: 'Batfide Signature';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/batfideSignature/BatfideSignature.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/batfideSignature/BatfideSignature.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/batfideSignature/BatfideSignature.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/batfideSignature/BatfideSignature.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/batfideSignature/BatfideSignature.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/batfideSignature/BatfideSignature.svg#BatfideSignature') format('svg');
  /* Legacy iOS */
}

/*Rightman Signature*/
@font-face {
  font-family: 'Rightman Signature';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/rightmanSignature/RightmanSignature.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/rightmanSignature/RightmanSignature.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/rightmanSignature/RightmanSignature.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/rightmanSignature/RightmanSignature.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/rightmanSignature/RightmanSignature.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/rightmanSignature/RightmanSignature.svg#RightmanSignature') format('svg');
  /* Legacy iOS */
}

/*Slow Smoke*/
@font-face {
  font-family: 'Slow Smoke';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/slowSmoke/SlowSmoke.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/slowSmoke/SlowSmoke.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/slowSmoke/SlowSmoke.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/slowSmoke/SlowSmoke.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/slowSmoke/SlowSmoke.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/slowSmoke/SlowSmoke.svg#SlowSmoke') format('svg');
  /* Legacy iOS */
}


/*Endalmin*/
@font-face {
  font-family: 'Endalmin';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/endalmin/Endalmin.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/endalmin/Endalmin.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/endalmin/Endalmin.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/endalmin/Endalmin.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/endalmin/Endalmin.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/endalmin/Endalmin.svg#Endalmin') format('svg');
  /* Legacy iOS */
}


/*
5th Grade Cursive
*/

/*5th Grade Cursive*/
@font-face {
  font-family: '5th Grade Cursive';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/5thGradeCursive/5thgradecursive-2.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/5thGradeCursive/5thgradecursive-2.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/5thGradeCursive/5thgradecursive-2.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/5thGradeCursive/5thgradecursive-2.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/5thGradeCursive/5thgradecursive-2.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/5thGradeCursive/5thgradecursive-2.svg#5thGradeCursive') format('svg');
  /* Legacy iOS */
}

/*Adine Kirnberg*/
@font-face {
  font-family: 'Adine Kirnberg';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/adineKirnberg/adinekir.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/adineKirnberg/adinekir.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/adineKirnberg/adinekir.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/adineKirnberg/adinekir.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/adineKirnberg/adinekir.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/adineKirnberg/adinekir.svg#AdineKirnberg-Script') format('svg');
  /* Legacy iOS */
}

/*Parisienne*/
@font-face {
  font-family: 'Parisienne';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Parisienne/Parisienne-Regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/Parisienne/Parisienne-Regular.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/Parisienne/Parisienne-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/Parisienne/Parisienne-Regular.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/Parisienne/Parisienne-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/Parisienne/Parisienne-Regular.svg#Parisienne-Regular') format('svg');
  /* Legacy iOS */
}

/*Honey Script*/
@font-face {
  font-family: 'Honey Script';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/honey_script/HoneyScript-Light.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/honey_script/HoneyScript-Light.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/honey_script/HoneyScript-Light.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/honey_script/HoneyScript-Light.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/honey_script/HoneyScript-Light.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/honey_script/HoneyScript-Light.svg#HoneyScript-Light') format('svg');
  /* Legacy iOS */
}

/*Mrs Saint Delafield*/
@font-face {
  font-family: 'Mrs Saint Delafield';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/MrsSaintDelafield/MrsSaintDelafield-Regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/MrsSaintDelafield/MrsSaintDelafield-Regular.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/MrsSaintDelafield/MrsSaintDelafield-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/MrsSaintDelafield/MrsSaintDelafield-Regular.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/MrsSaintDelafield/MrsSaintDelafield-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/MrsSaintDelafield/MrsSaintDelafield-Regular.svg#MrsSaintDelafield-Regular') format('svg');
  /* Legacy iOS */
}


/*BrockScript*/
@font-face {
  font-family: 'BrockScript';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/BrockScript/BrockScript.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/BrockScript/BrockScript.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/BrockScript/BrockScript.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/BrockScript/BrockScript.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/BrockScript/BrockScript.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/BrockScript/BrockScript.svg#BrockScript') format('svg');
  /* Legacy iOS */
}


/*EuphoriaScript-Regular*/
@font-face {
  font-family: 'EuphoriaScript-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Euphoria_Script/EuphoriaScript-Regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/Euphoria_Script/EuphoriaScript-Regular.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/Euphoria_Script/EuphoriaScript-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/Euphoria_Script/EuphoriaScript-Regular.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/Euphoria_Script/EuphoriaScript-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/Euphoria_Script/EuphoriaScript-Regular.svg#EuphoriaScript-Regular') format('svg');
  /* Legacy iOS */
}

/*FlaemischeKanzleischrift*/
@font-face {
  font-family: 'FlaemischeKanzleischrift';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/flaemische-kanzleischrift/FlaemischeKanzleischrift.eot');
  src: local(''),
    url('fonts/flaemische-kanzleischrift/FlaemischeKanzleischrift.eot') format('embedded-opentype'),
    url('fonts/flaemische-kanzleischrift/FlaemischeKanzleischrift.woff2') format('woff2'),
    url('fonts/flaemische-kanzleischrift/FlaemischeKanzleischrift.woff') format('woff'),
    url('fonts/flaemische-kanzleischrift/FlaemischeKanzleischrift.ttf') format('truetype'),
    url('fonts/flaemische-kanzleischrift//FlaemischeKanzleischrift.svg#FlaemischeKanzleischrift') format('svg');
}


/*Gianna*/
@font-face {
  font-family: 'Gianna';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/gianna/Gianna.eot');
  src: local(''),
    url('fonts/gianna/Gianna.eot') format('embedded-opentype'),
    url('fonts/gianna/Gianna.woff2') format('woff2'),
    url('fonts/gianna/Gianna.woff') format('woff'),
    url('fonts/gianna/Gianna.ttf') format('truetype'),
    url('fonts/gianna/Gianna.svg#Gianna') format('svg');
}

/*Qwigley*/
@font-face {
  font-family: 'Qwigley';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Qwigley/Qwigley-Regular.eot');
  src: local(''),
    url('fonts/Qwigley/Qwigley-Regular.eot') format('embedded-opentype'),
    url('fonts/Qwigley/Qwigley-Regular.woff2') format('woff2'),
    url('fonts/Qwigley/Qwigley-Regular.woff') format('woff'),
    url('fonts/Qwigley/Qwigley-Regular.ttf') format('truetype'),
    url('fonts/Qwigley/Qwigley-Regular.svg#Qwigley-Regular') format('svg');
}

/**/
/*@font-face {
  font-family: '';
  font-style: normal;
  font-weight: 400;
  src: url('fonts');
  src: local(''),
    url('fonts') format('embedded-opentype'),
    url('fonts') format('woff2'),
    url('fonts') format('woff'),
    url('fonts') format('truetype'),
    url('fonts') format('svg');
}
*/
/*BantengStory*/
@font-face {
  font-family: 'BantengStory';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/banteng-story/BantengStory.eot');
  src: local(''),
    url('fonts/banteng-story/BantengStory.eot') format('embedded-opentype'),
    url('fonts/banteng-story/BantengStory.woff2') format('woff2'),
    url('fonts/banteng-story/BantengStory.woff') format('woff'),
    url('fonts/banteng-story/BantengStory.ttf') format('truetype'),
    url('fonts/banteng-story/BantengStory.svg#BantengStoryRegular') format('svg');
}


/*Famulred*/
@font-face {
  font-family: 'Famulred';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/famulred/Famulred.eot');
  src: local(''),
    url('fonts/famulred/Famulred.eot') format('embedded-opentype'),
    url('fonts/famulred/Famulred.woff2') format('woff2'),
    url('fonts/famulred/Famulred.woff') format('woff'),
    url('fonts/famulred/Famulred.ttf') format('truetype'),
    url('fonts/famulred/Famulred.svg#FamulredRegular') format('svg');
}

/*Italianno*/
@font-face {
  font-family: 'Italianno';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Italianno/Italianno-Regular.eot');
  src: local(''),
    url('fonts/Italianno/Italianno-Regular.eot') format('embedded-opentype'),
    url('fonts/Italianno/Italianno-Regular.woff2') format('woff2'),
    url('fonts/Italianno/Italianno-Regular.woff') format('woff'),
    url('fonts/Italianno/Italianno-Regular.ttf') format('truetype'),
    url('fonts/Italianno/Italianno-Regular.svg#Italianno-Regular') format('svg');
}

/*Adusian Signature*/
@font-face {
  font-family: 'Adusian Signature';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/adusian_signature/AdusianSignature.eot');
  src: local(''),
    url('fonts/adusian_signature/AdusianSignature.eot') format('embedded-opentype'),
    url('fonts/adusian_signature/AdusianSignature.woff2') format('woff2'),
    url('fonts/adusian_signature/AdusianSignature.woff') format('woff'),
    url('fonts/adusian_signature/AdusianSignature.ttf') format('truetype'),
    url('fonts/adusian_signature/AdusianSignature.svg#AdusianSignature-Regular') format('svg');
}

/*ChanticleerRomanNF*/
@font-face {
  font-family: 'ChanticleerRomanNF';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/chanticleerroman/ChanticleerRomanNF.eot');
  src: local(''),
    url('fonts/chanticleerroman/ChanticleerRomanNF.eot') format('embedded-opentype'),
    url('fonts/chanticleerroman/ChanticleerRomanNF.woff2') format('woff2'),
    url('fonts/chanticleerroman/ChanticleerRomanNF.woff') format('woff'),
    url('fonts/chanticleerroman/ChanticleerRomanNF.ttf') format('truetype'),
    url('fonts/chanticleerroman/ChanticleerRomanNF.svg#ChanticleerRomanNF') format('svg');
}

/*engebrechtreRg*/
@font-face {
  font-family: 'engebrechtreRg';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/engebrechtre/engebrechtreRg.eot');
  src: local(''),
    url('fonts/engebrechtre/engebrechtreRg.eot') format('embedded-opentype'),
    url('fonts/engebrechtre/engebrechtreRg.woff2') format('woff2'),
    url('fonts/engebrechtre/engebrechtreRg.woff') format('woff'),
    url('fonts/engebrechtre/engebrechtreRg.ttf') format('truetype'),
    url('fonts/engebrechtre/engebrechtreRg.svg#EngebrechtreRg-Regular') format('svg');
}

/*Flanella*/
@font-face {
  font-family: 'Flanella';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/flanella/Flanella.eot');
  src: local(''),
    url('fonts/flanella/Flanella.eot') format('embedded-opentype'),
    url('fonts/flanella/Flanella.woff2') format('woff2'),
    url('fonts/flanella/Flanella.woff') format('woff'),
    url('fonts/flanella/Flanella.ttf') format('truetype'),
    url('fonts/flanella/Flanella.svg#Flanella') format('svg');
}

/*MrBedfort-Regular*/
@font-face {
  font-family: 'MrBedfort-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Mr_Bedfort/MrBedfort-Regular.eot');
  src: local(''),
    url('fonts/Mr_Bedfort/MrBedfort-Regular.eot') format('embedded-opentype'),
    url('fonts/Mr_Bedfort/MrBedfort-Regular.woff2') format('woff2'),
    url('fonts/Mr_Bedfort/MrBedfort-Regular.woff') format('woff'),
    url('fonts/Mr_Bedfort/MrBedfort-Regular.ttf') format('truetype'),
    url('fonts/Mr_Bedfort/MrBedfort-Regular.svg#MrBedfort-Regular') format('svg');
}

/*Rhesmanisa*/
@font-face {
  font-family: 'Rhesmanisa';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/rhesmanisa/Rhesmanisa.eot');
  src: local(''),
    url('fonts/rhesmanisa/Rhesmanisa.eot') format('embedded-opentype'),
    url('fonts/rhesmanisa/Rhesmanisa.woff2') format('woff2'),
    url('fonts/rhesmanisa/Rhesmanisa.woff') format('woff'),
    url('fonts/rhesmanisa/Rhesmanisa.ttf') format('truetype'),
    url('fonts/rhesmanisa/Rhesmanisa.svg#Rhesmanisa-Regular') format('svg');
}

/*Bona Nova*/
@font-face {
  font-family: 'Bona Nova';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Bona_Nova/BonaNova-Regular.eot');
  src: local(''),
    url('fonts/Bona_Nova/BonaNova-Regular.eot') format('embedded-opentype'),
    url('fonts/Bona_Nova/BonaNova-Regular.woff2') format('woff2'),
    url('fonts/Bona_Nova/BonaNova-Regular.woff') format('woff'),
    url('fonts/Bona_Nova/BonaNova-Regular.ttf') format('truetype'),
    url('fonts/Bona_Nova/BonaNova-Regular.svg#BonaNova-Regular') format('svg');
}

/*Formal Script*/
@font-face {
  font-family: 'Formal Script';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Petit_Formal_Script/PetitFormalScript-Regular.eot');
  src: local(''),
    url('fonts/Petit_Formal_Script/PetitFormalScript-Regular.eot') format('embedded-opentype'),
    url('fonts/Petit_Formal_Script/PetitFormalScript-Regular.woff2') format('woff2'),
    url('fonts/Petit_Formal_Script/PetitFormalScript-Regular.woff') format('woff'),
    url('fonts/Petit_Formal_Script/PetitFormalScript-Regular.ttf') format('truetype'),
    url('fonts/Petit_Formal_Script/PetitFormalScript-Regular.svg#PetitFormalScript-Regular') format('svg');
}

/*Calligraffitti*/
@font-face {
  font-family: 'Calligraffitti';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Calligraffitti/Calligraffitti-Regular.eot');
  src: local(''),
    url('fonts/Calligraffitti//Calligraffitti-Regular.eot') format('embedded-opentype'),
    url('fonts/Calligraffitti/Calligraffitti-Regular.woff2') format('woff2'),
    url('fonts/Calligraffitti/Calligraffitti-Regular.woff') format('woff'),
    url('fonts/Calligraffitti/Calligraffitti-Regular.ttf') format('truetype'),
    url('fonts/Calligraffitti/Calligraffitti-Regular.svg#Calligraffitti-Regular') format('svg');
}

/*Rochester*/
@font-face {
  font-family: 'Rochester';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Rochester/Rochester-Regular.eot');
  src: local(''),
    url('fonts/Rochester/Rochester-Regular.eot') format('embedded-opentype'),
    url('fonts/Rochester/Rochester-Regular.woff2') format('woff2'),
    url('fonts/Rochester/Rochester-Regular.woff') format('woff'),
    url('fonts/Rochester/Rochester-Regular.ttf') format('truetype'),
    url('fonts/Rochester/Rochester-Regular.svg#Rochester-Regular') format('svg');
}


/*Arizonia*/
@font-face {
  font-family: 'Arizonia';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Arizonia/Arizonia-Regular.eot');
  src: local(''),
    url('fonts/Arizonia/Arizonia-Regular.eot') format('embedded-opentype'),
    url('fonts/Arizonia/Arizonia-Regular.woff2') format('woff2'),
    url('fonts/Arizonia/Arizonia-Regular.woff') format('woff'),
    url('fonts/Arizonia/Arizonia-Regular.ttf') format('truetype'),
    url('fonts/Arizonia/Arizonia-Regular.svg#Arizonia-Regular') format('svg');
}


/*Stalemate*/
@font-face {
  font-family: 'Stalemate';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Stalemate/Stalemate-Regular.eot');
  src: local(''),
    url('fonts/Stalemate/Stalemate-Regular.eot') format('embedded-opentype'),
    url('fonts/Stalemate/Stalemate-Regular.woff2') format('woff2'),
    url('fonts/Stalemate/Stalemate-Regular.woff') format('woff'),
    url('fonts/Stalemate/Stalemate-Regular.ttf') format('truetype'),
    url('fonts/Stalemate/Stalemate-Regular.svg#Stalemate-Regular') format('svg');
}


/*Attentica 4F*/
@font-face {
  font-family: 'Attentica 4F';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/attentica-4f/Attentica4FUltraLight.eot');
  src: local(''),
    url('fonts/attentica-4f/Attentica4FUltraLight.eot') format('embedded-opentype'),
    url('fonts/attentica-4f/Attentica4FUltraLight.woff2') format('woff2'),
    url('fonts/attentica-4f/Attentica4FUltraLight.woff') format('woff'),
    url('fonts/attentica-4f/Attentica4FUltraLight.ttf') format('truetype'),
    url('fonts/attentica-4f/Attentica4FUltraLight.svg#Attentica4F-UltraLight') format('svg');
}


/*League Script*/
@font-face {
  font-family: 'League Script';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/league-script/LeagueScript.eot');
  src: local(''),
    url('fonts/league-script/LeagueScript.eot') format('embedded-opentype'),
    url('fonts/league-script/LeagueScript.woff2') format('woff2'),
    url('fonts/league-script/LeagueScript.woff') format('woff'),
    url('fonts/league-script/LeagueScript.ttf') format('truetype'),
    url('fonts/league-script/LeagueScript.svg#LeagueScript') format('svg');
}

/***Penguin Attack
Minya Nouvelle
Butterfly Kids
Mystery Quest
Henny Penny
Life Savers
 */

/*Penguin Attack*/
@font-face {
  font-family: 'Penguin Attack';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/PenguinAttack/PenguinAttack.eot');
  src: local(''),
    url('fonts/PenguinAttack/PenguinAttack.eot') format('embedded-opentype'),
    url('fonts/PenguinAttack/PenguinAttack.woff2') format('woff2'),
    url('fonts/PenguinAttack/PenguinAttack.woff') format('woff'),
    url('fonts/PenguinAttack/PenguinAttack.ttf') format('truetype'),
    url('fonts/PenguinAttack/PenguinAttack.svg#PenguinAttack') format('svg');
}

/*Minya Nouvelle*/
@font-face {
  font-family: 'Minya Nouvelle';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/minya-nouvelle/MINYN___.eot');
  src: local(''),
    url('fonts/minya-nouvelle/MINYN___.eot') format('embedded-opentype'),
    url('fonts/minya-nouvelle/MINYN___.woff2') format('woff2'),
    url('fonts/minya-nouvelle/MINYN___.woff') format('woff'),
    url('fonts/minya-nouvelle/MINYN___.ttf') format('truetype'),
    url('fonts/minya-nouvelle/MINYN___.svg#MinyaNouvelle') format('svg');
}

/*Butterfly Kids*/
@font-face {
  font-family: 'Butterfly Kids';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Butterfly_Kids/ButterflyKids-Regular.eot');
  src: local(''),
    url('fonts/Butterfly_Kids/ButterflyKids-Regular.eot') format('embedded-opentype'),
    url('fonts/Butterfly_Kids/ButterflyKids-Regular.woff2') format('woff2'),
    url('fonts/Butterfly_Kids/ButterflyKids-Regular.woff') format('woff'),
    url('fonts/Butterfly_Kids/ButterflyKids-Regular.ttf') format('truetype'),
    url('fonts/Butterfly_Kids/ButterflyKids-Regular.svg#ButterflyKids-Regular') format('svg');
}

/*Mystery Quest*/
@font-face {
  font-family: 'Mystery Quest';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Mystery_Quest/MysteryQuest-Regular.eot');
  src: local(''),
    url('fonts/Mystery_Quest/MysteryQuest-Regular.eot') format('embedded-opentype'),
    url('fonts/Mystery_Quest/MysteryQuest-Regular.woff2') format('woff2'),
    url('fonts/Mystery_Quest/MysteryQuest-Regular.woff') format('woff'),
    url('fonts/Mystery_Quest/MysteryQuest-Regular.ttf') format('truetype'),
    url('fonts/Mystery_Quest/MysteryQuest-Regular.svg#MysteryQuest') format('svg');
}

/*Henny Penny*/
@font-face {
  font-family: 'Henny Penny';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Henny_Penny/HennyPenny-Regular.eot');
  src: local(''),
    url('fonts/Henny_Penny/HennyPenny-Regular.eot') format('embedded-opentype'),
    url('fonts/Henny_Penny/HennyPenny-Regular.woff2') format('woff2'),
    url('fonts/Henny_Penny/HennyPenny-Regular.woff') format('woff'),
    url('fonts/Henny_Penny/HennyPenny-Regular.ttf') format('truetype'),
    url('fonts/Henny_Penny/HennyPenny-Regular.svg#HennyPenny-Regular') format('svg');
}

/*Life Savers*/
@font-face {
  font-family: 'Life Savers';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Life_Savers/LifeSavers-Regular.eot');
  src: local(''),
    url('fonts/Life_Savers/LifeSavers-Regular.eot') format('embedded-opentype'),
    url('fonts/Life_Savers/LifeSavers-Regular.woff2') format('woff2'),
    url('fonts/Life_Savers/LifeSavers-Regular.woff') format('woff'),
    url('fonts/Life_Savers/LifeSavers-Regular.ttf') format('truetype'),
    url('fonts/Life_Savers/LifeSavers-Regular.svg#LifeSavers-Regular') format('svg');
}

/******************************    AI FONT FAMILY END ****************************************/
.badScriptFontFamily {
  font-family: "Bad Script";
}

.bilboFontFamily {
  font-family: "Bilbo";
}

.bilboSwashFontFamily {
  font-family: "Bilbo Swash Caps";
}

.dancingScriptFontFamily {
  font-family: "Dancing Script";
}

.moonDanceFontFamily {
  font-family: "Moon Dance";
}

.greatVibesFontFamily {
  font-family: "Great Vibes";
}

.sacramentoFontFamily {
  font-family: "Sacramento";
}

.alexBrushFontFamily {
  font-family: "Alex Brush";
}

.alluraFontFamily {
  font-family: "Allura";
}

.senjaSantuyFontFamily {
  font-family: "Senja Santuy";
}

.hoarselySingleLineFontFamily {
  font-family: "Hoarsely Single Line";
}

.sansilkFontFamily {
  font-family: "Sansilk";
}

.reallyFreeFontFamily {
  font-family: "Really Free";
}

.freeForPalestineFontFamily {
  font-family: "Free for Palestine";
}

.barokahSignatureFontFamily {
  font-family: "Barokah Signature";
}

.travelNovemberFontFamily {
  font-family: "Travel November";
}

.sundiaryFontFamily {
  font-family: "Sundiary";
}

.salonicaFontFamily {
  font-family: "Salonica";
}

.scriptinaProFontFamily {
  font-family: "Scriptina Pro";
}

.rosabeliaFontFamily {
  font-family: "Rosabelia";
}

.astaginaSignatureFontFamily {
  font-family: "Astagina Signature";
}

.batfideSignatureFontFamily {
  font-family: "Batfide Signature";
}

.rightmanSignatureFontFamily {
  font-family: "Rightman Signature";
}

.slowSmokeFontFamily {
  font-family: "Slow Smoke";
}

.endalminFontFamily {
  font-family: "Endalmin";
}

.fifthGradeCursiveFontFamily {
  font-family: "5th Grade Cursive";
}

.adineKirnbergFontFamily {
  font-family: "Adine Kirnberg";
}

.parisienneFontFamily {
  font-family: "Parisienne";
}

.honeyScriptFontFamily {
  font-family: "Honey Script";
}

.mrsSaintDelafieldFontFamily {
  font-family: "Mrs Saint Delafield";
}

.brockScriptFontFamily {
  font-family: "BrockScript";
}

.euphoriaScriptFontFamily {
  font-family: "EuphoriaScript-Regular";
}

.flaemischeKanzleischriftFontFamily {
  font-family: "FlaemischeKanzleischrift";
}

.giannaFontFamily {
  font-family: "Gianna";
}

.qwigleyFontFamily {
  font-family: "Qwigley";
}


.bantengStoryFontFamily {
  font-family: "BantengStory";
}

.famulredFontFamily {
  font-family: "Famulred";
}

.italiannoFontFamily {
  font-family: "Italianno";
}

.adusianSignatureFontFamily {
  font-family: "Adusian Signature";
}

.chanticleerRomanNFFontFamily {
  font-family: "ChanticleerRomanNF";
}

.engebrechtreRgFontFamily {
  font-family: "engebrechtreRg";
}

.flanellaFontFamily {
  font-family: "Flanella";
}

.mrBedfortRegularFontFamily {
  font-family: "MrBedfort-Regular";
}

.rhesmanisaFontFamily {
  font-family: "Rhesmanisa";
}

.bonaNovaFontFamily {
  font-family: "Bona Nova";
}

.formalScriptFontFamily {
  font-family: "Formal Script";
}

.calligraffittiFontFamily {
  font-family: "Calligraffitti";
}

.rochesterFontFamily {
  font-family: "Rochester";
}


.arizoniaFontFamily {
  font-family: "Arizonia";
}

.euphoriaScriptFontFamily {
  font-family: "Euphoria Script";
}

.stalemateFontFamily {
  font-family: "Stalemate";
}

.attentica4FFontFamily {
  font-family: "Attentica 4F";
}

.leagueScriptFontFamily {
  font-family: "League Script";
}

.penguinAttackFontFamily {
  font-family: "Penguin Attack";
}

.minyaNouvelleFontFamily {
  font-family: "Minya Nouvelle";
}

.butterflyKidsFontFamily {
  font-family: "Butterfly Kids";
}

.mysteryQuestFontFamily {
  font-family: "Mystery Quest";
}

.hennyPennyFontFamily {
  font-family: "Henny Penny";
}

.lifeSaversFontFamily {
  font-family: "Life Savers";
}


/*.FontFamily { font-family: ""; } */

/************************************  EMAIL SIGNATURES STYLING ******************************/
/*
esh => emailSignaturesHeader
essh => emailSignaturesSettingsHeader


*/
.emailSignature-wrapper {
  background-color: #e6edf5;
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;

}

.esh {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 4rem !important;
  flex-direction: column;

}

.esh-conainer {
  display: flex;
  ;
  justify-content: center;

}

.esh-options {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  font-size: 1.2rem;
  line-height: 1.25;
  border-radius: 4rem;
  width: auto;
  box-shadow: none;
  background-color: #f7f9fc;
}

.esh-options-item {
  margin-bottom: 0;
  border-radius: 4rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;

  display: inline-flex;
  flex-direction: row;
  position: relative;
  font-size: 1.2rem;
  line-height: 1.25;
  width: auto;
  box-shadow: none;
}

.esh-options-item :hover {
  color: #fff !important;
  background-color: #485f85 !important;
  border-radius: 4rem;
}

.esh-options-selected-item {
  color: #fff !important;
  background-color: #485f85 !important;
}

.esh-options-item-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1.5rem;
}



/*EMAIL SIGNATURE GENERIC SETTIGNS START */
.es-editor {
  display: flex !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  margin-bottom: 20px;
  padding-bottom: 100px;
  ;
}

.es-settings-panel {
  top: 0 !important;
  position: relative;
  margin-right: 5rem !important;
  position: relative;
  top: 0;
  overflow-y: visible;
  border-radius: 2rem;
  padding-bottom: 10rem;
  margin-top: 0;
  width: 40vw;
  min-height: 200px;
  background-color: white;

}

.essh {
  background-color: #f7f9fc !important;
  padding-left: 1rem !important;
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
  text-align: left;
}

.es-preview {
  position: relative;
  margin-right: 5rem !important;
  position: relative;
  overflow-y: visible;
  border-radius: 2rem;
  padding-bottom: 10rem;
  margin-top: 0;
  width: 50vw;
  min-height: 200px;
  background-color: white;
}

.top-border-radius {
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;
}

.ess-fields-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/*EMAIL SIGNATURE SETTINGS FIELD*/
.ess-field-wrapper {
  position: relative;
}

.ess-field-label {
  left: 4.5rem;
  position: absolute;
  top: -0.5rem;
  padding-left: 4px;
  background: white;
  padding-right: 4px;
}

.ess-field {
  display: block;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 30px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
}

/********************************* AI SIGNATURE GENERATOR -  START *******************************/

#react-root .ai-categories-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

#react-root .ai-btn {
  background-color: white;
  color: #9f9999;
  padding: 6px;
  margin-right: 5px;
  border: 1px solid #dcd4d4;
  width: 100%;
}


#react-root .ai-btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 150px;
  flex-shrink: 0;
  background-color: white;
  color: black;
  margin: 5px;
}

#react-root .ai-btn-box:nth-child(3n) {
  margin-right: 5px;
  margin-left: 5px;
}

#react-root .ai-btn-box:nth-child(3n + 1) {
  margin-left: 5px;
  margin-right: 5px;
}


#react-root .ai-btn.active {
  border-color: #6c757d;
  color: black;
}


/********************************* AI SIGNATURE GENERATOR -  END   *******************************/





/*********************************  INVOICE GENERATOR START *******************************/

.invoice-container {
  max-width: 1000px !important;
  align-items: center;
  margin: 20px auto;
  padding: 20px;
  border: 2px solid #fdfafa;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.invoice-container .invoice-wrapper {
  padding-right: 30px;
}

.invoice-container logo {
  width: 200px;
  height: auto;
}

.invoice-container .invoiceFrom {
  width: 250px;
}

.invoice-container .billTo {
  width: 200px;
}

.invoice-container .shipTo {
  width: 200px;
}

.invoice-container .invoiceNumber {
  width: 200px;
  float: right;
}

.invoice-container .inputContainer {
  margin-top: 2px;
}

.invoice-container .inputTextRight {
  text-align: right;
}

.invoice-container .inputBorder {
  border: unset;
  border: 1px solid #FFFFFF;
}

.invoice-container input:focus {
  box-shadow: unset;
  outline: 0 none;
}

.invoice-container textarea:focus {
  box-shadow: unset;
  outline: 0 none;
}



.invoice-container .inputBorder:hover {
  border: 1px solid rgb(206, 203, 203);
}

.invoice-container .inputLeftPadding {
  padding-left: 0px;
}

/*1000 =>  600, 150, 100, 150*/
.invoice-container .lineItemHeaderContainer {
  display: flex;
  justify-content: start;
  background: black;
  border-radius: 5px;
}

.invoice-container .lineItemContainer {
  display: flex;
  justify-content: start;
  margin-top: 2px;
}

.invoice-container .lineItemDescription {
  width: 600px;
}

.invoice-container .lineItemDescriptionPadding {
  padding-right: 2px;
}

.invoice-container .lineItemQuantity {
  width: 150px;
}

.invoice-container .lineItemQuantityPadding {
  padding-right: 2px;
  ;
}

.invoice-container .lineItemUnitPrice {
  width: 100px;
}

.invoice-container .lineItemUnitPricePadding {
  padding-right: 2px;
}

.invoice-container .lineItemAmont {
  width: 150px;
}

.invoice-container .inputHeading {
  background: black;
  color: white;
  border: unset !important;
}

.invoice-container .inputHeading:focus {
  background: black;
  color: white;
  border: unset !important;
}


/*********************************  INVOICE GENERATOR END *******************************/